<template>
    <v-container fluid>
        <v-card elevation="6">
            <v-card-title>
                {{this.$t('settings.lang_editRole')}}
            </v-card-title>
            <v-divider class="pa-0 ma-0"/>
            <v-card-text>
                <v-form lazy-validation ref="form">
                    <v-row align="center" justify="center">
                        <v-col class="text-center" cols="12" sm="6">
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field :rules="[v => !!v]" dense :label="$t('settings.lang_roleName')" outlined
                                                  v-model="form.roleName"/>
                                </v-col>

                                <v-col cols="12">
                                    <color-picker :rules="[v => !!v]" dense outlined :label="$t('erp.lang_warecreate_color')" v-bind:color="form.roleColor"
                                                  v-model="form.roleColor"/>
                                </v-col>

                                <v-col class="text-right" cols="12">
                                    <v-btn :disabled="this.loading" :loading="this.loading" @click="update()"
                                           color="primary">
                                        {{this.$t('generic.lang_save')}}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    import ColorPicker from "../../../common/colorPicker";
    import {ENDPOINTS} from "../../../../config";
    import {Events} from "../../../../plugins/events";

    export default {
        name: "RoleEditComponent",
        components: {ColorPicker},
        data: () => {
            return {
                loading: false,
                form: {},
            }
        },
      mounted() {
            this.loadData();
        },
        methods: {
            loadData() {
                this.axios.post(ENDPOINTS.SETTINGS.BASIC.ROLES.GET, {userRoleID: this.$route.params.id}).then((res) => {
                    this.form = res.data.formfillData.textFields;
                }).catch((err) => {
                    Events.$emit("showSnackbar", {
                        color: "error",
                        message: err.message
                    });
                })
            },
            update() {
                if (!this.$refs.form.validate()) return;
                this.form.userRoleID = this.$route.params.id
                this.loading = true;
                this.axios.post(ENDPOINTS.SETTINGS.BASIC.ROLES.UPDATE, this.form).then((res) => {

                    if (res.data.status === 'SUCCESS') {
                        Events.$emit("showSnackbar", {
                            message: this.$t("generic.lang_success"),
                            color: "success"
                        });

                        this.$router.push('/settings/basicSettings/roles');
                    } else {
                        Events.$emit("showSnackbar", {
                            color: "error",
                            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
                        });
                    }
                }).catch((err) => {
                    Events.$emit("showSnackbar", {
                        color: "error",
                        message: err.message
                    });
                }).finally(() => {
                    this.loading = false;
                })
            }
        }
    }
</script>

<style scoped>

</style>
